<script>
import Layout from "@/router/layouts/franchise";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],

      "Withdrawal History": "Histórico de Saques",
      Bank: "Banco",
      Value: "Valor",
      Fee: "Taxa",
      Transfered: "Transferido",
      Transaction: "Transação",
      "Awaiting processing": "Aguardando processamento",
      Completed: "Completo",
      "View Transaction": "Ver Transação",
    },
    es: {
      months: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],

      "Withdrawal History": "Historia de Retirada",
      Bank: "Banco",
      Value: "Valor",
      Fee: "Taxa",
      Transfered: "Transferido",
      Transaction: "Transacción",
      "Awaiting processing": "En espera de procesamiento",
      Completed: "Completo",
      "View Transaction": "Ver Transacción",
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      table: {
        heade: ["Date", "PIX", "Value", "Status"],
        body: null,
      },
    };
  },
  methods: {
    getWithdrawals() {
      this.table.body = null;

      api
        .get("franchise/wallet/withdrawal")
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
          } else {
            this.table.body = null;
          }
        })
        .catch((error) => {
          if (error) {
            this.table.body = []
          }
        })
    },
  },
  mounted() {
    this.getWithdrawals();
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/franchise/wallet/extract/cash">Extrato de Saque</router-link></li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>Histórico de Saque</h3>
        <p></p>
      </div>
    </div>

    <div v-if="!table.body" class="text-center">
      <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
    </div>

    <div v-else-if="table.body && table.body.length == 0" class="card">
      <div class="card-body min-vh-25 d-flex justify-content-center align-items-center">
        <div class="text-center">
          <i class="far fa-sad-cry font-size-24"></i><br>
          nenhum registro encontrado
        </div>
      </div>
    </div>

    <div v-else class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-centered table-nowrap">
            <thead class="thead-light">
              <tr>
                <th v-for="th in table.heade" :key="th">{{ t(th) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(td, index) in table.body" :key="index">
                <td v-for="(data, row) in td" :key="row">
                  <div v-if="row === 'value'">
                    {{ data | currency }}
                  </div>
                  <div v-else-if="row === 'status'">
                    <span v-if="data === '0'" class="badge badge-soft-warning font-size-12">{{ t("Awaiting processing") }}</span>
                    <span v-else-if="data === '1'" class="badge badge-soft-success font-size-12">{{ t("Completed") }}</span>
                    <span v-else-if="data === '2'" class="badge badge-soft-danger font-size-12">{{ t("Canceled") }}</span>
                    <span v-else-if="data === '3'" class="badge badge-soft-success font-size-12">{{ t("Completed") }}</span>
                  </div>
                  <div v-else-if="row === 'payment'">
                    {{ data }}
                  </div>
                  <div v-else class="notranslate">
                    {{ data }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>
